body,html {color: black !important; background-color: #272b30 !important; }
header + section  { padding-top: 52px; padding-bottom: 2.1em }
footer { background-color: #3e444c; position:fixed; bottom:0; width: 100%; height: 2em; border-top: 1px solid #bbb; padding: 4px }
footer span.left { float: left; }
footer span.right { float: right; }
pre { color: black}
.breadcrumb label { color: black; }

.sidebar .list-group {
    font-size: 75%;
}

.sidebar .list-group-item {
    padding: 2px;
    background:#111;
    color: #62c462;
    border:0;
    font-family: Hack, monospace
}

.main {
    font-family: Hack, monospace;
}

.sidebar li.list-group-item.active,.sidebar a.list-group-item.active {
    color: #111;
    background-color: #62c462;
}

@media (min-width: 768px) {
    .hide-sm { display: none !important; }
}

@media (min-width: 768px) {
    .sidebar {
        position:fixed;
        top: 52px;
        bottom: 30px;
        left: 0;
        width: 24vw;
        overflow: auto;
    }
    .main {
        position: fixed;
        top: 52px;
        bottom: 30px;
        left: 25vw;
        right: 0;
        overflow: auto;
    }
}

@media (min-width: 992px) {
    .sidebar {
        position:fixed;
        top: 52px;
        bottom: 30px;
        left: 0;
        width: 24vw;
        overflow: auto;
    }
    .main {
        position: fixed;
        top: 52px;
        bottom: 30px;
        left: 25vw;
        right: 0;
        overflow: auto;
    }
}
